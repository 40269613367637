<template>
  <div class="newbox">
    <div class="center" :class="{'mb-center':isMinDevice}">
      <div class="commercial">
        <img class="w100" :src="onlineUrl + finalFuncImgs[5]" alt="" />
      </div>
      <!-- 新闻排行 -->
      <div v-if="!isMinDevice" class="toplisi" >
        <div class="l_box">
          <div class="topitem point"  v-for="(item, index) in newlist.slice(0, 3)" :key="index">
            <div class="l_item" >
              <img src="../../assets/images/New/TOP1.png" alt=""  v-show="index == 0" />
              <img src="../../assets/images/New/TOP2.png" alt=""  v-show="index == 1" />
              <img src="../../assets/images/New/TOP3.png" alt=""  v-show="index == 2" />
            </div>
            <div class="r_item"  @click="todetail(item.id)">
              <div class="title" >{{ item.title }}</div>
              <div class="content" v-html="item.content"></div>
            </div>
          </div>
        </div>
        <div class="r_box">
          <div class="topitem point" v-for="(item, index) in newlist.slice(3, 6)" :key="index">
            <div class="l_item" >
              <span v-show="index == 0">4</span>
              <span v-show="index == 1">5</span>
              <span v-show="index == 2">6</span>
            </div>
            <div class="r_item"  @click="todetail(item.id)">
              <div class="title">{{ item.title }}</div>
              <div class="content" v-html="item.content"></div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="toplisi" :class="{'mb-toplisi':isMinDevice}">
        <div class="l_box" :class="{'mb_nbox':isMinDevice}">
          <div class="topitem point" :class="{'mb-topitem':isMinDevice}" v-for="(item, index) in newlist.slice(0, 6)" :key="index">
            <div class="l_item" :class="{'mb_l_item':isMinDevice}">
              <img src="../../assets/images/New/TOP1.png" alt="" :class="{'mb-img':isMinDevice}" v-show="index == 0" />
              <img src="../../assets/images/New/TOP2.png" alt="" :class="{'mb-img':isMinDevice}" v-show="index == 1" />
              <img src="../../assets/images/New/TOP3.png" alt="" :class="{'mb-img':isMinDevice}" v-show="index == 2" />
              <span v-show="index == 3" :class="{'mb_span':isMinDevice}">4</span>
              <span v-show="index == 4" :class="{'mb_span':isMinDevice}">5</span>
              <span v-show="index == 5" :class="{'mb_span':isMinDevice}">6</span>
            </div>
            <div class="r_item" :class="{'mb_r_item':isMinDevice}" @click="todetail(item.id)">
              <div class="title" :class="{'mb_title':isMinDevice}">{{ item.title }}</div>
              <div class="content" :class="{'mb_content':isMinDevice}" v-html="item.content"></div>
            </div>
          </div>
        </div>
    
      </div>
      <!-- 新闻列表 -->
      <div class="newlist">
        <div class="newitem point" :class="{'mb_newitem':isMinDevice}" v-for="(item, index) in newlist" :key="index">
          <div class="imgbox" :class="{'mb_imgbox':isMinDevice}" @click="todetail(item.id)">
            <img :src="onlineUrl + item.image" alt="" />
          </div>
          <div class="infobox" :class="{'mb_infobox':isMinDevice}">
            <div class="title" :class="{'mb_info_title':isMinDevice}" @click="todetail(item.id)">{{ item.title }}</div>
            <div class="flge" :class="{'mb_flge':isMinDevice}">{{ item.flag }}</div>
            <div class="infomain" :class="{'mb_infomain':isMinDevice}" v-html="item.content" @click="todetail(item.id)"></div>
            <div style="
                width: 100%;
                display: flex;
                justify-content: flex-end;
                margin-top: 0.3rem;
              ">
              <div class="good" :class="{'mb_good':isMinDevice}">
                <div class="time" :class="{'mb_time':isMinDevice}">
                  {{ new Date(item.createtime * 1000).format("dd-MM-yyyy ") }}
                </div>
                <div class="kudos">
                  <div class="icon point" v-show="userInfo.id != ''" :class="{'mb_icon':isMinDevice}">
                    <img src="../../assets/images/New/good.png" alt="" v-show="item.good == false" @click="acGood(item.id, index)" />
                    <img src="../../assets/images/New/acgood.png" alt="" :style="isMinDevice? '    width: 1rem; height: 1rem;':'' " v-show="item.good == true" @click="acGood(item.id, index)" />
                  </div>
                  <div class="icon point" v-show="userInfo.id == ''">
                    <img src="../../assets/images/New/good.png" alt="" />
                  </div>
                  <p class="pc_p" :class="{'mb_p':isMinDevice}">{{ item.good_num }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getNewdataApi, acGoodApi } from "../../network/api";
import { mapGetters, mapState } from "vuex";
// 处理时间戳
Date.prototype.format = function (format) {
  var o = {
    "M+": this.getMonth() + 1, // month
    "d+": this.getDate(), // day
    "h+": this.getHours(), // hour
    "m+": this.getMinutes(), // minute
    "s+": this.getSeconds(), // second
    "q+": Math.floor((this.getMonth() + 3) / 3), // quarter
    S: this.getMilliseconds(),
    // millisecond
  };
  if (/(y+)/.test(format) || /(Y+)/.test(format)) {
    format = format.replace(
      RegExp.$1,
      (this.getFullYear() + "").substr(4 - RegExp.$1.length)
    );
  }
  for (var k in o) {
    if (new RegExp("(" + k + ")").test(format)) {
      format = format.replace(
        RegExp.$1,
        RegExp.$1.length == 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
      );
    }
  }
  return format;
};

export default {
  data() {
    return {
      toplist: [],
      newlist: [],
      good: false,
      acgoodindex: null,
    };
  },
  created() {
    this.getNewdata();
  },
  computed: {
    ...mapGetters(["finalFuncImgs"]),
    ...mapState(["userInfo", "onlineUrl", 'isMinDevice']),
  },
  methods: {
    async getNewdata() {
      console.log(this.userInfo);
      let id = JSON.parse(localStorage.getItem('userInfo'));
      if (!this.userInfo) {
        const res = await getNewdataApi(
          id ? id.id : ""
        );
        this.newlist = res.data.Items;
      } else {
        const res = await getNewdataApi(
          this.userInfo.id ? this.userInfo.id : ""
        );
        this.newlist = res.data.Items;
      }
    },
    acGood(id, i) {
      console.log(id, i);
      // this.acgoodindex = id;
      // if (this.good == false) {
      //   this.good = true;
      // } else {
      //   this.good = false;
      // }
      acGoodApi(id).then((res) => {
        this.getNewdata();
      });
    },
    // 点击新闻详情页
    todetail(v) {
      this.$router.push({
        path: "/newDetail",
        query: { id: v, user_id: this.userInfo.id },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.newbox {
  width: 100%;
  height: 100%;

  .center {
    width: 14.78rem;
    margin: 0 auto;

    .commercial {
      width: 100%;
      //   height: 0.8rem;
      //   background-color: #dfdfdf;
      margin-top: 0.9rem;
    }
    .mb-commercial {
      height: 2.25rem;
      background: pink;
    }

    .toplisi {
      width: 100%;
      height: 3.35rem;
      background: #f5faff;
      margin-top: 0.75rem;
      overflow: hidden;
      display: flex;

      .l_box {
        margin-left: 0.24rem;
        margin-top: 0.3rem;

        .topitem {
          width: 7.01rem;
          height: 0.91rem;
          border-bottom: 0.02rem solid #cbd7e4;
          display: flex;
          justify-content: space-between;
          margin-top: 0.06rem;

          &:nth-child(3) {
            border: 0;
          }

          .l_item {
            width: 0.5rem;
            .mb-img {
              width: 1.2rem;
              height: 1.2rem;
            }
            img {
              width: 0.4rem;
            }
          }
          .mb_l_item {
            width: 10%;
          }

          .r_item {
            width: 6.48rem;

            .title {
              width: 100%;
              height: 0.25rem;
              font-size: 0.18rem;
              font-weight: 600;
              color: #000000;
            }

            .mb_title {
              height: 0.7rem;
              font-size: 0.512rem;
              // white-space: nowrap;
              // overflow: hidden;
              // text-overflow: ellipsis;
            }
            .content {
              width: 100%;
              height: 0.5rem;
              font-size: 0.16rem;
              font-weight: 400;
              color: #000000;
              margin-top: 0.06rem;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            }
            .mb_content {
              height: 1.3rem;
              font-size: 0.4rem;
              line-height: 0.45rem;
            }
          }
          .mb_r_item {
            width: 90%;
          }
        }
        .mb-topitem {
          width: 100%;
          height: 2.2rem;
        }
      }
      .mb_nbox {
        width: 100%;
      }
      .r_box {
        margin-left: 0.24rem;
        margin-top: 0.3rem;

        .topitem {
          width: 7.01rem;
          height: 0.91rem;
          border-bottom: 0.02rem solid #cbd7e4;
          display: flex;
          justify-content: space-between;
          margin-top: 0.06rem;

          &:nth-child(3) {
            border: 0;
          }

          .l_item {
            width: 0.5rem;
            text-align: center;

            span {
              height: 0.23rem;
              font-size: 0.2rem;
              font-family: PingFang SC-Semibold, PingFang SC;
              font-weight: 600;
              color: #000000;
              line-height: 0.23rem;
            }
            .mb_span {
              font-size: 0.512rem;
            }
          }

          .r_item {
            width: 6.48rem;

            .title {
              width: 100%;
              height: 0.25rem;
              font-size: 0.18rem;
              font-weight: 600;
              color: #000000;
            }

            .content {
              width: 100%;
              height: 0.5rem;
              font-size: 0.16rem;
              font-weight: 400;
              color: #000000;
              margin-top: 0.06rem;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            }
          }
        }
      }
    }
    .mb-toplisi {
      height:unset;
      width: 17rem;
    }

    .newlist {
      width: 100%;
      margin-top: 0.75rem;
      margin-bottom: 1rem;

      .newitem {
        width: 100%;
        height: 2.35rem;
        background: #ffffff;
        box-shadow: 0 0.04rem 0.04rem 0 rgba(0, 0, 0, 0.1);
        border-radius: 0.06rem;
        opacity: 1;
        border: 0.01rem solid #d9d9d9;
        margin-bottom: 0.25rem;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        position: relative;

        .imgbox {
          width: 4.58rem;
          height: 1.87rem;
          overflow: hidden;

          img {
            display: block;
            width: 100%;
            height: 100%;
          }
        }

        .infobox {
          width: 9.52rem;

          .title {
            width: 100%;
            height: 0.4rem;
            font-size: 0.28rem;
            font-weight: 600;
            color: #000000;
            line-height: 0.3rem;
          }

          .mb_info_title {
            width: 10.24rem;
            height: 0.87rem;
            font-size: 0.6rem;
            line-height: 0.87rem;
          }
          .flge {
            padding: 0 0.2rem;
            height: 0.24rem;
            background: linear-gradient(90deg, #fc5c7d 0%, #eeba00 100%);
            border-radius: 0 0.06rem 0 0.06rem;
            position: absolute;
            top: 0;
            right: 0;
            text-align: center;
            line-height: 0.24rem;
            color: #ffffff;
          }
          .mb_flge {
            height: 0.56rem;
            border-radius: 0 0.3rem 0 0.3rem;
            line-height: 0.56rem;
            font-size: 0.5rem;
          }

          .infomain {
            width: 100%;
            height: 0.76rem;
            font-size: 0.14rem;
            font-weight: 400;
            color: #000000;
            line-height: 0.16rem;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            margin-top: 0.1rem;
          }
          .mb_infomain {
            height: 2.2rem;
            font-size: 0.5rem;
            line-height: 0.55rem;
          }

          .good {
            width: 2.96rem;
            height: 0.45rem;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .time {
              font-size: 0.16rem;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #5f5f5f;
              line-height: 0.2rem;
            }
            .mb_time {
              font-size: 0.5rem;
            }

            .kudos {
              display: flex;
              align-items: center;
              justify-content: space-between;

              .icon {
                width: 0.3rem;
                height: 0.3rem;
                margin-right: 0.1rem;
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                  display: block;
                }
              }
              .mb_icon {
                width: 0.7rem;
                height: 0.7rem;
                margin-right: 1rem;
                display: flex;
                justify-content: center;
                align-items: center;
                img {
                  display: block;
                  width: 0.7rem;
                  height: 0.7rem;
                }
              }

              .pc_p {
                margin: 0;
                height: 0.4rem;
                font-size: 0.16rem;
                font-weight: 400;
                color: #8f8f8f;
                line-height: 0.45rem;
                margin-left: 0.05rem;
              }
              .mb_p {
                height: 0.7rem;
                font-size: 0.6rem;
                line-height: 0.8rem;
              }
            }
          }
          .mb_good {
            width: 6.86rem;
            height: 0.7rem;
          }
        }
        .mb_infobox {
          width: 10.24rem;
        }
      }
      .mb_newitem {
        width: 17.15rem;
        height: 5.12rem;
        border-radius: 0.3rem;
        box-shadow: 0 0.2rem 0.2rem 0 rgba(0, 0, 0, 0.1);
        .mb_imgbox {
          width: 5.5rem;
          height: 4rem;
          img {
            display: block;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
  .mb-center {
    width: 17.15rem;
    margin: 0 auto;
  }
}
</style>